import React from "react";
import { useParams, useNavigate } from "react-router-dom";

function ProductDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const products = [
    {
      id: 1,
      name: "Laptop Gaming",
      desc: `ASUS ROG STRIX G531GU
      Spesifikasi:
      Intel Core i7, 16GB RAM, 512GB SSD, NVIDIA GTX 1660Ti 6GB, 15.6 Inch FHD 120Hz.`,
      price: "Rp 15.000.000",
      image: "/images/laptop.jpg",
    },
    {
      id: 2,
      name: "Monitor 24 Inch",
      desc: "Monitor Full HD dengan resolusi tinggi.",
      price: "Rp 2.500.000",
      image: "/images/monitor.jpg",
    },
    {
      id: 3,
      name: "Keyboard Mechanical",
      desc: "Keyboard mekanik dengan desain ergonomis.",
      price: "Rp 800.000",
      image: "/images/gambar3.jpg",
    },
    {
      id: 4,
      name: "Mouse Gaming",
      desc: "Mouse Gaming Berwarna Warni",
      price: "Rp 500.000",
      image: "/images/gambar4.jpg",
    },
    {
      id: 5,
      name: "Headset Gaming",
      desc: "Headset Gaming Berwarna Warni",
      price: "Rp 1.000.000",
      image: "/images/gambar5.jpg",
    },
    {
      id: 6,
      name: "Mousepad Gaming",
      desc: "MousePad Gaming Berwarna Warni",
      price: "Rp 200.000",
      image: "/images/gambar6.jpg",
    },
  ];

  const product = products.find((p) => p.id === parseInt(id));

  return (
    <div className="container py-5">
      {product ? (
        <>
          <h2 className="text-3xl font-bold mb-4">{product.name}</h2>
          <img
            src={product.image}
            alt={product.name}
            className="mb-4 w-full max-w-md mx-auto rounded shadow"
          />
          <p className="text-gray-700 mb-2">
            {product.desc.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>

          <p className="text-blue-500 font-bold mb-4">{product.price}</p>
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Kembali
          </button>
        </>
      ) : (
        <p>Produk tidak ditemukan.</p>
      )}
    </div>
  );
}

export default ProductDetail;
