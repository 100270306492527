import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Products() {
  const products = [
    { id: 1, name: "Laptop Gaming", price: "Rp 15.000.000" },
    { id: 2, name: "Monitor 24 Inch", price: "Rp 2.500.000" },
    { id: 3, name: "Keyboard Mechanical", price: "Rp 800.000" },
    { id: 4, name: "Mouse Gaming", price: "Rp 500.000" },
    { id: 5, name: "Headset Gaming", price: "Rp 1.000.000" },
    { id: 6, name: "Mousepad Gaming", price: "Rp 200.000" },
    { id: 1, name: "Laptop Gaming", price: "Rp 15.000.000" },
    { id: 2, name: "Monitor 24 Inch", price: "Rp 2.500.000" },
    { id: 3, name: "Keyboard Mechanical", price: "Rp 800.000" },
    { id: 4, name: "Mouse Gaming", price: "Rp 500.000" },
    { id: 5, name: "Headset Gaming", price: "Rp 1.000.000" },
    { id: 6, name: "Mousepad Gaming", price: "Rp 200.000" },
    
  ];

  return (
    <section id="products" className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Produk Unggulan</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <motion.div
              key={product.id}
              className="p-6 bg-gray-100 shadow-md rounded-lg hover:shadow-2xl transform hover:scale-105 transition duration-300"
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <h3 className="text-xl font-semibold mb-2">{product.name}</h3>
              <p className="text-blue-500 font-bold mb-4">{product.price}</p>
              <Link
                to={`/product/${product.id}`}
                className="text-blue-500 hover:underline"
              >
                Selengkapnya
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Products;
