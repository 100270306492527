import React from "react";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="bg-blue-600 text-white py-6">
      <div className="container mx-auto text-center">
        <p>
          &copy;
          <span
            onClick={scrollToTop}
            className="text-white no-underline hover:text-red-200 cursor-pointer"
          >
            2024 ITC Computer.
          </span>{" "}
          Semua Hak Dilindungi.
        </p>
        <p>
          <a
            href="mailto:contact@itccomputer.com"
            className="text-white no-underline hover:text-red-200"
          >
            Email: contact@itccomputer.com
          </a>{" "}
          |{" "}
          <a
            href="https://wa.me/6282119789748"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white no-underline hover:text-red-200"
          >
            Telepon: 0812-3456-7890
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
