import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Services() {
  const services = [
    { id: 1, title: "Penjualan PC/Laptop", desc: "Beragam pilihan komputer sesuai kebutuhan Anda." },
    { id: 2, title: "Sparepart Komputer", desc: "Komponen berkualitas dengan harga terjangkau." },
    { id: 3, title: "Pemasangan Jaringan", desc: "Layanan instalasi jaringan internet." },
    { id: 4, title: "Servis", desc: "Perbaikan komputer dan laptop dengan teknisi berpengalaman." },
    { id: 5, title: "Aksesoris", desc: "Aksesoris lengkap untuk menunjang produktivitas Anda." },
  ];

  return (
    <section id="services" className="py-20 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Layanan Kami</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={service.id}
              className="p-6 bg-white shadow-lg rounded-lg hover:shadow-2xl transform hover:scale-105 transition duration-300"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
              <p className="text-gray-700 mb-4">{service.desc}</p>
              <Link
                to={`/service/${service.id}`}
                className="text-blue-500 hover:underline"
              >
                Selengkapnya
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
