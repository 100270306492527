import React, { useState, useEffect } from "react";

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 w-full z-10 p-4 transition-all duration-300 ${
        scrolled ? "bg-gray-800 text-white" : "bg-blue-500 text-white"
      }`}
    >
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold">ITC Computer</h1>
        <nav>
          <ul className="flex space-x-4">
            <li><a href="#services" className="hover:text-gray-200">Layanan</a></li>
            <li><a href="#products" className="hover:text-gray-200">Produk</a></li>
            <li><a href="#contact" className="hover:text-gray-200">Kontak</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
