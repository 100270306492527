import React from "react";
import { useParams, useNavigate } from "react-router-dom";

function ServiceDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const services = [
    { id: 1, title: "Penjualan PC/Laptop", desc: "Kami menyediakan berbagai jenis PC dan laptop." },
    { id: 2, title: "Sparepart Komputer", desc: "Berbagai komponen untuk kebutuhan komputer Anda." },
    { id: 3, title: "Pemasangan Jaringan & CCTV", desc: "Instalasi jaringan dan CCTV profesional." },
    { id: 4, title: "Servis", desc: "Perbaikan dan pemeliharaan komputer oleh teknisi berpengalaman." },
    { id: 5, title: "Aksesoris", desc: "Aksesoris komputer yang lengkap dan berkualitas." },
  ];

  const service = services.find((s) => s.id === parseInt(id));

  return (
    <div className="container py-5">
      {service ? (
        <>
          <h2 className="text-3xl font-bold mb-4">{service.title}</h2>
          <p className="text-gray-700 mb-2">{service.desc}</p>
          <button
            className="btn btn-primary"
            onClick={() => navigate(-1)}
          >
            Kembali
          </button>
        </>
      ) : (
        <p>Layanan tidak ditemukan.</p>
      )}
    </div>
  );
}

export default ServiceDetail;
