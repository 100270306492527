import React from "react";
import { motion } from "framer-motion";

function Hero() {
  return (
    <motion.section
      className="bg-blue-500 text-white text-center py-20"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="container mx-auto">
        <motion.h2
          className="text-4xl font-bold mb-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          Selamat Datang di ITC Computer
        </motion.h2>
        <motion.p
          className="text-lg mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          Solusi kebutuhan komputer Anda, dari penjualan hingga layanan teknis.
        </motion.p>
        <motion.a
          href="#services"
          className="bg-white text-blue-600 px-6 py-3 rounded-lg hover:bg-gray-200"
          whileHover={{ scale: 1.1 }}
        >
          Jelajahi Layanan Kami
        </motion.a>
      </div>
    </motion.section>
  );
}

export default Hero;
